import React, { useEffect, useState } from "react";
import { Button, TextField, Card, Box } from "@mui/material";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { deleteDoc, renderDoc, uploadImage } from "../../requests/s3";
// import { selectUiProp } from "../../store/uiSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import { patchAnnonces } from "../../requests/annonces";
import { setMessage } from "../../store/errorSuccessSlice";
// import { useRouter } from "next/router";
import { useParams } from "react-router-dom";

type Props = {
  path: string[];
};

const GridImage = ({ path }: Props) => {
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const dispatch = useDispatch();
  const props = useSelector(selectAnnonceProp(path.slice(1), []));
  if (props.length === 0) {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1)],
        value: [{ image: null, description: "" }],
      })
    );
  }

  const addRow = () => {
    let currentData = [...props];
    currentData.push({ image: null, description: "" });
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1)],
        value: currentData,
      })
    );
    const dataPath = path.slice(1).join("/");
    patchAnnonces(annonceId as string, [
      {
        op: "replace",
        path: `/data/data/${dataPath}`,
        value: currentData,
      },
    ])
      .then((res) => {
        if (res === "error") {
          dispatch(
            setMessage({ type: "error", message: "Une erreur est survenue" })
          );
        }
      })
      .catch(() =>
        dispatch(
          setMessage({ type: "error", message: "Une erreur est survenue" })
        )
      );
  };

  const deleteRow = (index: number) => {
    let currentData = [...props];
    if (currentData[index].image?.imageUrl !== undefined) {
      const imagePath = currentData[index].image?.imageUrl;
      deleteDoc(imagePath);
    }
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), index, "image"],
        value: null,
        undo: true,
      })
    );
    currentData.splice(index, 1);
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1)],
        value: currentData,
      })
    );
    const dataPath = path.slice(1).join("/");
    patchAnnonces(annonceId as string, [
      {
        op: "replace",
        path: `/data/data/${dataPath}`,
        value: currentData,
      },
    ])
      .then((res) => {
        if (res === "error") {
          dispatch(
            setMessage({ type: "error", message: "Une erreur est survenue" })
          );
        }
      })
      .catch(() =>
        dispatch(
          setMessage({ type: "error", message: "Une erreur est survenue" })
        )
      );
  };

  return (
    <>
      {props.map((item: { image: {}; description: string }, index: number) => (
        <Box key={index} sx={{ borderBottom: "2px solid black", pb: 1, mt: 2 }}>
          <DropzoneComponent sentFile={item.image} index={index} path={path} />

          <TextField
            sx={{ my: 2 }}
            fullWidth
            multiline
            rows={2}
            value={item.description}
            placeholder="Ajouter une légende à votre photo"
            onChange={(e) => {
              dispatch(
                setAnnonceProp({
                  id: annonceId,
                  path: ["data", ...path.slice(1), index, "description"],
                  value: e.target.value,
                })
              );
            }}
          />

          <Button
            variant="outlined"
            sx={{ padding: "10px 20px" }}
            onClick={() => deleteRow(index)}
          >
            supprimer
          </Button>
        </Box>
      ))}

      <Button
        style={{ margin: "14px auto", display: "block" }}
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          padding: "14px 20px",
          borderRadius: "8px",
          fontWeight: "700",
          "&:hover": { color: "primary.main" },
        }}
        onClick={() => addRow()}
      >
        Ajouter une photo
      </Button>
    </>
  );
};

const DropzoneComponent = ({ sentFile, index, path }: any) => {
  const [file, setFile] = useState<any>([]);
  // const selected = useSelector(selectUiProp("selected"));
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getUrl = async (path: string) => {
      return await renderDoc(path);
    };
    if (sentFile !== null) {
      if (sentFile?.hasOwnProperty("imageUrl")) {
        getUrl(sentFile.imageUrl).then((res) =>
          setFile([
            {
              ...sentFile,
              imageUrl: (res as { url: string; data: string }).url,
            },
          ])
        );
      } else {
        setFile([sentFile]);
      }
    } else if (sentFile === null) {
      setFile([]);
    }
  }, [sentFile]);

  const updateFiles = async (incommingFiles: any) => {
    setFile(incommingFiles);

    const url = await uploadImage({
      fileName: incommingFiles[0].file.name as string,
      annonceId: annonceId as string,
      file: incommingFiles[0],
    });
    const file = incommingFiles[0];
    delete file.file;
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), index, "image"],
        value: { ...file, imageUrl: url },
      })
    );
    const dataPath = path.slice(1).join("/");
    patchAnnonces(annonceId as string, [
      {
        op: "replace",
        path: `/data/data/${dataPath}/${index}/image`,
        value: { ...file, imageUrl: url },
      },
    ])
      .then((res) => {
        if (res === "error") {
          dispatch(
            setMessage({ type: "error", message: "Une erreur est survenue" })
          );
        }
      })
      .catch(() =>
        dispatch(
          setMessage({ type: "error", message: "Une erreur est survenue" })
        )
      );
  };
  const removeFile = (id: string | number | undefined) => {
    const fileToDelete = file.filter((x: any) => x.id === id);
    let filePath = fileToDelete[0].imageUrl.split("amazonaws.com/");
    filePath = filePath[1].split("?");
    console.log(path);
    if (!filePath.includes("annonceTemplate")) {
      deleteDoc(filePath[0]);
    }
    setFile(file.filter((x: any) => x.id !== id));
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), index, "image"],
        value: null,
      })
    );
    const dataPath = path.slice(1).join("/");
    patchAnnonces(annonceId as string, [
      {
        op: "replace",
        path: `/data/data/${dataPath}/${index}/image`,
        value: null,
      },
    ])
      .then((res) => {
        if (res === "error") {
          dispatch(
            setMessage({ type: "error", message: "Une erreur est survenue" })
          );
        }
      })
      .catch(() =>
        dispatch(
          setMessage({ type: "error", message: "Une erreur est survenue" })
        )
      );
  };
  return (
    <Dropzone
      onChange={updateFiles}
      value={file}
      maxFiles={1}
      accept="image/*, video/*"
      localization="FR-fr"
      header={false}
      footer={false}
      label="Déposez vos fichiers ici, ou cliquez pour parcourir"
    >
      {file.map((f: any) => {
        return (
          <FileMosaic key={f.imageUrl} {...f} preview onDelete={removeFile} />
        );
      })}
    </Dropzone>
  );
};

export default GridImage;
