// import {
//   S3Client,
//   ListObjectsV2Command,
//   GetObjectCommand,
//   DeleteObjectCommand,
//   PutObjectCommand,
// } from "@aws-sdk/client-s3";
// import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const openApiURL =
  "https://48heklt6di.execute-api.eu-west-1.amazonaws.com/dev/s3";

const fetcher = (body: any) =>
  fetch(openApiURL, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

const getDocs = async (ownerId: string) => {
  return fetcher({ ownerId, handler: "getDocs" })
    .then((res) => res.json())
    .then(({ contents }) => contents);
};

export const renderDoc = async (path: string) => {
  return fetcher({ path, handler: "renderDoc" })
    .then((res) => res.json())
    .then(({ doc }) => doc);
};

export const deleteDoc = async (path: string) => {
  return fetcher({ path, handler: "deleteDoc" })
    .then((res) => res.json())
    .then(({ deleteRes }) => deleteRes);
};

export const uploadImage = async ({
  fileName,
  file,
  type = "annonce",
  annonceId,
}: {
  fileName: string;
  file: any;
  type?: string;
  annonceId?: string;
}) => {
  const body = await file.file.arrayBuffer();
  const fileType = file.type;
  const options = {
    fileName,
    fileType,
    type,
    handler: "uploadImage",
  };
  const sendToS3 = (uploadUrl: string) =>
    fetch(uploadUrl, {
      method: "PUT", // Use PUT for file uploads to presigned URLs
      body: file.file, // The file to upload
      headers: {
        "Content-Type": file.type, // Ensure the correct MIME type
      },
    });
  if (type === "annonce") {
    const { uploadUrl, objectKey } = await fetcher({ ...options, annonceId })
      .then((res) => res.json())
      .then(({ upload }) => upload);
    await sendToS3(uploadUrl);
    return objectKey;
    // const command = new PutObjectCommand({
    //   Bucket: "form-io-eu-west-1",
    //   Key: `direct-proprietaire/annonces/${annonceId}/${fileName}`,
    //   Body: body,
    //   ContentType: file.type,
    // });
    // try {
    //   await S3client.send(command);
    //   return `direct-proprietaire/annonces/${annonceId}/${fileName}`;
    // } catch (err) {
    //   console.error(err);
    // }
  } else if (type === "blog") {
    const { uploadUrl, objectKey } = await fetcher(options)
      .then((res) => res.json())
      .then(({ upload }) => upload);
    await sendToS3(uploadUrl);
    return objectKey;
    // const command = new PutObjectCommand({
    //   Bucket: "form-io-eu-west-1",
    //   Key: `direct-proprietaire/blog/${fileName}`,
    //   Body: body,
    //   ContentType: file.type,
    // });
    // console.log("sdfg", fileName);
    // try {
    //   await S3client.send(command);
    //   return `direct-proprietaire/blog/${fileName}`;
    // } catch (err) {
    //   console.error(err);
    // }
  }
};

const checkFile = async (annonceId: string, name: string) => {
  return fetcher({ annonceId, name, handler: "checkFile" })
    .then((res) => res.json())
    .then(({ check }) => check);
  // const params = {
  //   Bucket: "form-io-eu-west-1",
  //   Prefix: `direct-proprietaire/annonces/${annonceId}/${name}`,
  // };

  // const command = new ListObjectsV2Command(params);

  // const response = await S3client.send(command);
  // return response;
};
