import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { selectAllArticles, setAllArticles } from "../store/articleSlice";
import { getArticles } from "../requests/articles";
import { setMessage } from "../store/errorSuccessSlice";
// import { renderDoc } from "../requests/s3";

type Props = {};

const BlogWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const articles = useSelector(selectAllArticles);
  useEffect(() => {
    if (articles.length === 0) {
      getArticles().then((res) => {
        if (res !== "error") {
          dispatch(setAllArticles(res));
        } else {
          dispatch(
            setMessage({
              type: "error",
              message: "Une erreur s'est produite",
            })
          );
        }
      });
    }
  }, [articles]);

  // const getImages = async (articles: any) => {
  //   const result = await Promise.all(
  //     articles.map(async (article: any) => {
  //       const res = await renderDoc(article.data.image.imageUrl);
  //       const buffer = {
  //         id: article.data.slug,
  //         image: (res as { url: string; data: string }).url,
  //       };
  //       return buffer;
  //     })
  //   );
  //   return result;
  // };
  return <Outlet />;
};

export default BlogWrapper;
